/**
 * @name toggleBurgerBarButtonMenu
 * @desc Toggles open and closed the menu associated with a burger bar button
 * @param {Element} burgerBarButton - A burger bar button element
 * @param {Element} menuElement - The menu element associated with the burger bar
 */
function toggleBurgerBarButtonMenu(burgerBarButton, menuElement) {
  const burgerBarActiveClassName = burgerBarButton.getAttribute(
    "data-client-open-classname",
  );
  const menuOpenClassName = menuElement.getAttribute("data-client-show-class");

  const isActive = burgerBarButton.classList.contains(burgerBarActiveClassName);

  if (!isActive) {
    menuElement.classList.add(menuOpenClassName);
    burgerBarButton.classList.add(burgerBarActiveClassName);
    burgerBarButton.setAttribute("aria-label", "close navigation");
    burgerBarButton.setAttribute("aria-expanded", "true");
  } else {
    menuElement.classList.remove(menuOpenClassName);
    burgerBarButton.classList.remove(burgerBarActiveClassName);
    burgerBarButton.setAttribute("aria-label", "open navigation");
    burgerBarButton.setAttribute("aria-expanded", "false");
  }
}

/**
 * @name getBurgerBarMenuElement
 * @returns The DOM element associated with the provided burger bar button
 * @param {Element} burgerBarButton - A burger bar button element
 */
function getBurgerBarMenuElement(burgerBarButton) {
  const menuId = burgerBarButton.getAttribute("data-client-menuId");
  const menuElement = document.getElementById(menuId);
  return menuElement;
}

const burgerBarButton = document.querySelector(
  '[data-client-hook="burger-menu"]',
);
if (burgerBarButton !== null) {
  const menuElement = getBurgerBarMenuElement(burgerBarButton);
  if (menuElement !== null) {
    burgerBarButton.addEventListener("click", () =>
      toggleBurgerBarButtonMenu(burgerBarButton, menuElement),
    );
  }
}
